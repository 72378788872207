<template>
  <div v-if="$vuetify.breakpoint.smAndUp" class="fill-height d-flex flex-column" style="height: 100%; z-index: 2;">
    <v-sheet color="primary" height="56px" class="d-flex align-center">
      <v-img
        height="40px"
        contain
        src="/tesys-white.png"
      />
    </v-sheet>
    <div class="flex-grow-1 d-flex">
      <div class="d-flex flex-column fill-height flex-grow-1">
        <div
          class="flex-grow-1"
          :style="{
            'overflow-y': 'auto',
          }">
          <div
            class="my-1 text-center">
            <v-sheet
              color="white"
              height="56px"
              class="rounded-sm menu-item mx-1"
              :class="{
                'rounded-r-0 active-menu-item': currentMatchedMenu == 'profile'
              }"
            >
              <h5 class="text-center grey--text" style="max-width: 128px">
                {{ userName }}
              </h5>
            </v-sheet>
            <v-chip
              small label color="#0097a7" class="mt-n8 white--text"
              active-class="primary darken-2"
              :to="{name: 'profile'}">
              <v-icon left>mdi-cog</v-icon>
              Configurações
            </v-chip>
          </div>

          <div class="mt-n3" />
          <v-card
            draggable="false"
            v-for="(item, i) in menu" :key="i"
            :to="item.to"
            min-width="120px"
            class="
              d-flex flex-column align-center
              text-center text-uppercase elevation-0
              mb-1 mx-1 pt-4 pb-3
              rounded-sm menu-item
            "
            :class="{
              'rounded-r-0 active-menu-item': currentMatchedMenu.includes(item.to.name),
            }"
          >
            <div class="d-flex align-center justify-center">
              <v-img
                v-if="item.img"
                max-width="35px"
                max-height="35px"
                :src="require(`@/assets/menu/${item.img}.png`)"
                contain
              />
              <v-icon v-else size="35" color="white">
                {{ item.icon }}
              </v-icon>
            </div>
            <div class="ma-0 white--text pt-1 caption">
              {{ item.text }}
            </div>
          </v-card>
        </div>
        <div class="pa-2">
          <v-btn
            color="primary"
            small
            class="py-4"
            outlined
            block
            @click="logout()"
          >
            Sair
          </v-btn>
        </div>
      </div>
      <div class="primary pl-1" />
    </div>
  </div>
  <v-navigation-drawer v-else right fixed v-model="active" width="auto" max-width="600px" style="display-block clear:both">
    <div class="fill-height primary darken-2 px-2" style="display: block" > 
      <div class="d-flex" style="display: block">
      <v-img
        max-width="100px"
        class="mx-auto"
        contain
        src="/tesys-white.png"/>
         <v-btn fixed right z-index="2" icon @click="active = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
    </div>
    <div class="mx-3">
      <h5 class="text-center" style="
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: white;
      ">
        {{ userName }}
      </h5>
      <v-btn
        small
        block
        color="#0097a7"
        class="white--text rounded-t-0"
        active-class="primary darken-2"
        :to="{ name: 'profile' }">
        <v-icon left>mdi-cog</v-icon>
        Configurações
      </v-btn>
    </div>
      <v-row class="ma-0">
        <v-col 
          :class="$vuetify.breakpoint.smAndDown ? 'col-6' : ''"
          v-for="(item, i) in menu"
          :key="i"
        >
          <v-card
            active-class="primary darken-2"
            :to="item.to"
            class="
              py-3
              px-1
              elevation-5
              rounded-sm
              primary">
            <!-- <div class="d-flex justify-center"><v-icon size="35" color="white">{{ item.icon }}</v-icon></div> -->
            <p class="m-0 text-center white--text pt-1">{{ item.text }}</p>
          </v-card>
        </v-col>
      </v-row>
        <v-btn
          color="white"
          small
          absolute
          bottom
          style="width: 95%"
          class="py-4"
          outlined
          @click="logout()"
        >
          Sair
        </v-btn>
    </div>
  </v-navigation-drawer>
</template>


<script>
import menu from "@/menu.js";

export default {
  name: "Menu",
  data: () => ({
    logoHeight: 120,
    loading: true,
    active: false,
  }),
  computed: {
    currentMatchedMenu(){
      return this.$route.matched[1].meta.id || "";
    },
    menu(){
      if (this.$store.state.user){
        return menu.filteredMenu(this.$store.state.user);
      }
    },
    userName(){
      if (this.$store.state.user){
        return (this.$store.state.user.name || "");
      }
    },
  },
  methods: {
    open(){
      this.active = true;
    },
    logout() {
      this.$store.commit("logout");
      this.$router.push({ name: "login" });
    },
  },
  watch: {
    active(){}
  }
};
</script>

<style scoped>
  .menu-item {
    transition: all 100ms ease-in;
    overflow: hidden;
    background-color: #0097a7;
  }
  .active-menu-item {
    transition: all 100ms ease-out;
    padding-right: 4px !important;
    margin-right: 0 !important;
    background-color: #007581;
  }
</style>
