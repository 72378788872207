<template>
  <div
    class="d-flex"
    :style="{
      height: `${$vuetify.breakpoint.height}px`,
    }"
  >
    <v-sheet width="140px" v-if="$vuetify.breakpoint.smAndUp">
      <v-navigation-drawer v-model="menu" permanent>
        <Menu ref="menu" />
      </v-navigation-drawer>
    </v-sheet>
    <v-sheet v-else>
        <Menu ref="menu"/>
    </v-sheet>
  
    <div class="flex-grow-1">
      <v-fade-transition leave-absolute>
        <router-view @openMenu="$refs.menu.open()" />
      </v-fade-transition>
    </div>
    
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";

export default {
  name: "Main",
  components: {
    Menu,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    menuHeight() {
      return this.$vuetify.breakpoint.height - 65; /*app bar*/
    },    
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push({ name: "login" });
    },
  },
  created() {
    if (!this.$store.state.logged) {
      this.$router.replace({ name: "login" });
    }
  },
};
</script>
