export default {
  menu: [
    {
      text: "Clientes",
      accountantRequired: true,
      icon: "mdi-domain",
      img: "icone_empresas",
      to: { name: "companies" },
    },
    { // para contadores, mesmo sem ser admin
      text: "Usuários",
      accountantRequired: true,
      icon: "mdi-account-multiple",
      img: "icone_usuarios",
      to: { name: "user-management" },
    },
    { // para clientes, mas somente se for admin
      text: "Usuários",
      clientRequired: true,
      adminRequired: true,
      icon: "mdi-account-multiple",
      img: "icone_usuarios",
      to: { name: "user-management" },
    },
    {
      text: "Caixa",
      icon: "mdi-cash-register",
      img: "icone_caixa",
      to: { name: "cash-flows" },
    },
    {
      text: "Documentos",
      accountantRequired: true,
      icon: "mdi-file-document-multiple",
      img: "icone_docs",
      to: { name: "documents" },
    },
    // ...(process.env.NODE_ENV === "production" ? []:[
      {
        text: "Formulários",
        accountantRequired: true,
        icon: "mdi-form-select",
        to: { name: "forms" },
      },
    // ]),
    {
      text: "Malha Fina",
      accountantRequired: true,
      icon: "mdi-account-cash",
      img: "icone_malha_fina",
      to: { name: "malha-fina"}
    },
  ],
  filteredMenu(user){
    if (user == undefined)
      return [];
    let menu;
    if (user.is_accountant) {
      menu = this.menu.filter((m) => !m.clientRequired);
    } else {
      menu = this.menu.filter((m) => !m.accountantRequired)
    }
    if (!user.is_admin){
      menu = menu.filter((m) => !m.adminRequired);
    }
    return menu;
  },
};
